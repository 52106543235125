import React, { Component } from 'react'

export default class Holding extends Component {
  render() {
    return (
      <div className="main">
        <div className="main-second">
            <div>
                <h1>Ibex Digital Services</h1>
                <a href="mailto:studio@theibex.net"><h2>Talk to us</h2></a>
            </div>
            <div>
                <h1 className="gradient-default">We do stuff.</h1>
                <h3>Obscure language? We know it.</h3>
                <h3>Server hosting? We do it.</h3>
                <h3>Lots of love? We give it.</h3>
            </div>
        </div>
      </div>
    )
  }
}
